<template>
    <carousel :nav="false"
              :items="1"
              :dots="true">

        <!-- Single Testimonial -->
        <div class="single-testimonial pt-65">
            <!-- Testimonial tittle -->
            <div class="testimonial-icon mb-45">
                <img src="../../assets/img/comment/samuel-maputso.png" class="ani-btn img-fluid" alt="">
            </div>
            <!-- Testimonial Content -->
            <div class="testimonial-caption text-center">
                <p>Acreditamos no potencial ilimitado dos líderes e organizações, no seu desejo de assumirem controlo do
                    seu destino e atingirem classe mundial</p>
                <!-- Rattion -->
                <div class="testimonial-ratting">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </div>
                <div class="rattiong-caption">
                    <span>Human Resources Management  <span> - BancABC</span> </span>
                </div>
            </div>
        </div>
        <!-- //Single Testimonial -->
    </carousel>
</template>

<script>
    import carousel from 'vue-owl-carousel'

    export default {
        name: "Testimonial",
        components: {carousel},
    }
</script>

<style scoped>
    img {
        width: 120px !important;
        height: 120px !important;
    }

</style>

<template>
    <main>
        <!-- We Trusted Start-->
        <div id="about" class="we-trusted-area trusted-padding">
            <div class="container">
                <div class="row d-flex align-items-end">
                    <div class="col-xl-7 col-lg-7">
                        <div class="trusted-img">
                            <img src="../../assets/img/team/wetrusted.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <div class="trusted-caption">
                            <h2 class="">Nossa Filosofia</h2>
                            <p class="">O nosso trabalho está assente nos princípios de <i>Mutuality, Enablement &
                                Growth.</i> Focamos em soluções mutuamente benéficas, intervimos como catalizadores e
                                facilitadores, sempre focados no crescimento sustentável dos líderes e dos negócios dos
                                nossos clientes.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- We Trusted End-->

        <!-- services Area Start-->
        <div class="services-area section-padding2">
            <div class="container">
                <!-- section tittle -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <h2>Nossos Valores</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-icon">
                                <span> <img src="../../assets/img/icon/excelencia.png"></span>
                            </div>
                            <div class="services-caption">
                                <h4>Excelência</h4>
                                <p>Trabalhamos arduamente para atingir níveis de desempenho cada vez mais elevados e
                                    soluções de elevada qualidade, pois nossos clientes e parceiros merecem nada menos
                                    que o melhor</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-icon">
                                <span><img src="../../assets/img/icon/compromisso.png"> </span>
                            </div>
                            <div class="services-caption">
                                <h4>Compromisso</h4>
                                <p>Buscamos afincadamente soluções inovadoras e sustentáveis para as necessidades dos
                                    nossos clientes, procurando sempre superar as suas expetativas</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-icon">
                                <span> <img src="../../assets/img/icon/respeito.png"> </span>
                            </div>
                            <div class="services-caption">
                                <h4>Respeito</h4>
                                <p>Respeitamos e valorizamos as ideias e contribuições dos nossos associados,
                                    parceiros e clientes pelo valor que eles tem. Abraçamos a diversidade como fonte
                                    de aprendizagem e enriquecimento mútuo</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-icon">
                                <span> <img src="../../assets/img/icon/integridade.png"> </span>
                            </div>
                            <div class="services-caption">
                                <h4>Integridade</h4>
                                <p>Agimos de forma ética, honesta, aberta e transparente em todas ocasiões. Cumprimos e
                                    fazemos cumprir as leis, políticas e procedimentos aplicáveis ao nosso negócio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- services Area End-->

        <!-- Testimonial Start -->
        <div class="testimonial-area fix">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-9 col-lg-9 col-md-9">
                        <div class="h1-testimonial-active">
                            <testimonial></testimonial>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Testimonial End -->
    </main>
</template>

<script>
    import Testimonial from './Testimonial'

    export default {
        name: "AboutContent",
        components: {
            Testimonial
        }
    }
</script>

<style scoped>

</style>
